@font-face {
    font-family: Tofino;
    src: url("/fonts/TofinoPersonal-Bold.otf") format("opentype");
}
@font-face {
    font-family: Source;
    src: url("/fonts/SourceSansPro-Regular.ttf") format("truetype");
}
 @-webkit-keyframes kenburns-bottom-left{0%{-webkit-transform:scale(1) translate(0,0);transform:scale(1) translate(0,0);-webkit-transform-origin:16% 84%;transform-origin:16% 84%}100%{-webkit-transform:scale(1.25) translate(-20px,15px);transform:scale(1.25) translate(-20px,15px);-webkit-transform-origin:left bottom;transform-origin:left bottom}}
 @keyframes kenburns-bottom-left{0%{-webkit-transform:scale(1) translate(0,0);transform:scale(1) translate(0,0);-webkit-transform-origin:16% 84%;transform-origin:16% 84%}100%{-webkit-transform:scale(1.25) translate(-20px,15px);transform:scale(1.25) translate(-20px,15px);-webkit-transform-origin:left bottom;transform-origin:left bottom}}

/* .rc-menu-submenu-popup {
    display: none !important;
} */

html {
    background: var(--navbar-background) !important;
    font-family: Source;
    font-weight: 200;
    show-tooltip: none !important;
}

* {
    show-tooltip: none !important;
}

.root {
    display: flex;
    
}

.maintenance{
    flex: 1 1;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
}
.bottom {
    /* align-self: flex-end; */
    /* flex: 1 */
}
.container {
    background: var(--primary-color);
    color: var(--primary-text-color) !important;
    /* padding-bottom: 25px; */
}

.navbar button{ 
    background: transparent !important;
    color: var(--navbar-text-color) !important;
}
.navbar {
    background: var(--navbar-background) !important;
    color: var(--navbar-text-color) !important;
}
#simple-menu ul {
    background: var(--primary-color) !important;
}


body {
    letter-spacing: 2px;
    font-weight: 200;
}

:focus{
    outline-color: transparent;
    outline-offset: 0;
    outline-style: none;
    outline-width: 0;
}
input:active{
    outline-offset: none;
}

::-moz-selection { /* Code for Firefox */
    color: #fff;
    background: #333;
  }
  
  ::selection {
    color: #fff;
    background: #333;
  }

li:hover{
    cursor: pointer;
}

/* .slick-track {
    width: 100%!important
} */

.footer {
    background: var(--footer-background) !important;
    color: var(--footer-text-color) !important;
}

.icon-whatsapp {
    height: 36px;
    width: 36px;
    position: fixed;
    bottom: 100px;
    right: 50px;
    z-index: 9999999;
}



.nav-social-content a{
    margin: 0 8px;
    margin-top: 5px;
}
.nav-social{
    margin-left: 10px
}


.tofino {
    font-family: Tofino !important;
    font-size: 1.4rem;
}
body, h3, h5, h4, h2, h6 {
    margin: 0;
    font-family: Tofino !important;
    font-weight: 200 !important;
}
p {
    margin: 0;
    font-family: 'Lato', sans-serif !important;
}
ul, li, .MuiButton-root {
    margin: 0;
    font-family: Source !important;
    font-size: 0.875rem;
    /* color: var(--navbar-text-color) !important; */
    font-weight: 400;
    letter-spacing: 0.02857em;
}
a {
    text-decoration: none !important;
}
h3 {
    font-size: 20px;
    font-weight: 400 !important;
}
h5 {
    font-size: 15px;
    color: #b8c7cc;
    font-weight: 200;
    margin-bottom: 10px;
}
h4 {
    font-size: 15px;
    /* color: #464749; */
    font-weight: 200;
}


.over-effect {
    -webkit-transition:all 0.5s ease;
    transition:all 0.5s ease;
}
.over-effect:hover {
    background-size: 105% !important;
}

.MuiMenu-paper {
    background: var(--navbar-background) !important;
    color: var(--navbar-text-color) !important;
}

.banner-title {
    font-family: Tofino !important;
    font-size: 2em;
    letter-spacing: 10px;
}

.full {
    height: 100vh;
    width: 100%;
}
.black {
    background-color: #000;
}
.content-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-footer {
    position: absolute;
    left: 100%;
    width: 100%;
    bottom: 0;
    background: #fff;
    height: 500px;
    display: flex;
    align-items: center;
}

.comments {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: auto;
    /* max-width: 1200px; */
}



.comments-slider {
    margin: auto;
    width: 80% !important;
    margin-top: 45px;
}

.comments-slider h3 {
    font-size: 1.5rem;
    text-align: center;
}

.comments-slider p {
    font-size: .95rem;
    text-align: right;
    margin-top: 15px;
    font-style: italic;
    font-weight: 100;
}

.general-modal .MuiDialog-paper{
    background: rgba(0, 0, 0, .9);
    border: 2px solid rgba(255, 255, 255, .8);
    padding: 2%;
    color: #fff;
}

.general-modal label {
    color: #fefefe !important;
}

.general-modal .MuiInput-formControl {
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, .6);
}
.general-modal .MuiInput-formControl .Mui-focused{
    color: #fff;
    border-bottom: 1px solid #fff !important;
}

.general-modal button {
    color: #fff;
    border: 1px solid #fff;
}
.general-modal button:hover {
    color: #333;
    background: #fff;
}
.contact {
    min-height: 80vh ;
    padding-bottom: 50px;
    background-color: var(--primary-color);
}

.contact-info {
    /* max-width: 1200px; */
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 25px
}

.contact-info h2, p {
    margin: 3px 0;
}
.contact-info h2 {
    font-size: 1.8rem
}


.content {
    /* max-width: 1200px; */
    margin: auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    color: #333 !important;
    margin: 40px auto;
}
.contentFull {
    max-width: 1200px;
    /* margin: auto; */
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #333 !important;
    margin: 40px auto;
}

.content h2 {
    font-size: 2rem;
    color: #333;
}

.content p {
    font-size: 1.5rem;
    color: #333;
}


.menu-open {
    right: 0;
    -webkit-transition: right .5s;
    transition: right .5s;
}

.menu-close {
    right: -1000px;
    -webkit-transition: right 1.4s;
    transition: right 1.4s;
}

.nav-mobile {
    display: none;
}

.nav-mobile-full {
    display: none;
}


@media (max-width: 600px) {
    .banner-title {

    }
    .comments-mobile {
        margin-bottom: 100px;
    }
    .product-category {
        text-align: center;
        margin-top: 50px;
    }
    .nav-desktop {
      display: none !important;
    }

    .product-list {
        padding-left: 15px;
        padding-right: 15px;
    }

    .input-newsletter {
        flex: 6 1!important;
        width: 100% !important;
    }
    .filter{
        align-items: center !important;
        background-color: rgba(0, 0, 0, .5);
    }
    .input-box {
        flex-direction: column!important;
    }

    .nav-mobile-full {
        display: flex !important;
    }
    
    .navbar {
        min-height: 100px !important;
    }

    .responsive ul {
        align-items: center!important;
        text-align: center!important;
    }
    .responsive {
        flex-direction: column !important;
        padding: 0 !important;
    }
    .responsive div{
        text-align: center;
    }
    .responsive h4{
        text-align: center;
        margin-bottom: 10px;
        margin-top: 25px;
    }

    .box-responsive {
        flex: none !important;
        margin-bottom: 25px!important;
        height: 40vh !important;
        width: 80%;
        margin: auto!important;
        padding: 0 !important;
        align-items: center !important;
    }

    .box-responsive h2 {
        /* margin: 30px 0; */
    }

    .hide-xs{
        display: none !important;
    }

    .nav-mobile {
        display: flex;
        justify-content: flex-end;
        padding-right: 25px;
        color: #333 !important;
        width: 100%;
        
    }
    .nav-mobile-full .hover-white {
        /* color: #; */
    }
    .nav-mobile-full {
        width: 100%;
        height: 90vh;
        background: var(--navbar-background) !important;
        color: var(--navbar-text-color) !important;
        box-shadow: -2px 0px 8px 6px var(--navbar-background);
        position: absolute;
        top: 10vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .hide-mobile {
        display: none !important;
    }
  }



/* Responside */
@media (max-width: 1000px) {
    .banner-title {
        font-size: 2rem;
        text-align: center;
    }
}
@media (max-width: 800px) {
    .banner-title {
        font-size: 1.4rem;
        text-align: center;
    }
    .content-content h2 {
        font-size: 1.2rem;
    }
    .content-content div {
        font-size: 1rem;
    }

    .content p {
        font-size: 1.2rem !important;
    }
    .footer {
        padding-bottom: 100px;
    }
    .wp-text-helper {
        display: none;
    }
    .icon-whatsapp {
        height: 26px;
        width: 26px;
        position: fixed;
        bottom: 50px;
        right: 50px;
    }
    .product-detail {
        width: 100% !important;
    }
    
}

.menu-custom {
    border: none !important;
    box-shadow: none !important;
    margin-top: 0 !important;
    background-color: var(--navbar-background) !important;
}

.menu-custom ul{
    background-color: var(--navbar-background) !important;
}
.menu-custom ul li{
    background-color: var(--navbar-background) !important;
}

.menu-custom-ul {
    background-color: var(--navbar-background) !important;
}
.rc-menu-submenu {
    background-color: var(--navbar-background) !important;
    border: none !important;
}
.rc-menu-submenu li:hover {
    background-color: var(--navbar-background) !important;
    /* background-color: rgb(91,53,251) !important; */
    border: none !important;
}
.rc-menu-sub:hover {
    /* background-color: rgb(91,53,251) !important; */
    background-color: var(--navbar-background) !important;
    border: none !important;
}
.rc-menu {
    background-color: var(--navbar-background) !important;
    border: none !important;
}
.rc-menu-submenu > .rc-menu {
    background-color: var(--navbar-background) !important;
    border: none !important;
}
.rc-menu-submenu-title {
    background-color: var(--navbar-background) !important;
    border: none !important;
}

.wp-text-helper {
    position: relative;
    left: -90px;
    font-size: 14px;
    width: 150px;
    color: #333;
    background-color: #fff;
    padding: 10px;
    border-radius: 4px;
}

.product-category {
    font-size: 28px;
    font-weight: 600;
    font-family: Source !important;
    border-bottom: 2px solid rgba(0, 0, 0, .04);
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-top: 25px;
}

.product-detail {
    flex: 1 1;
    height: 25vw;
    width: auto;
    margin-top: 20px;
}

.rc-menu-submenu-title:hover {
    color: #fff !important;
}
.rc-menu-item:hover {
    color: #fff !important;
}
.nav-label:hover span{
    color: #fff !important;
}

.image-full {
    max-width: 40vw;
    max-height: 40vh;
}

.img-row {
    max-width: 70vw !important;
    height: 50vh;
}

.img-round {
    border-radius: 5px;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, .2);
}


.gustos-slider {
    min-height: 70vh;
    width: 100%;
    padding-top: 80px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    background-image: url('/assets/gustos-background.jpg');
    background-size: cover;
    text-align: center;
    margin-top: -10px;
}
.gustos-slider h2 {
    font-size: 2.5rem;
    color: #fff;
}
.gustos-box h4 {
    color: #aed83a!important;
}
.gustos-box h3 {
    color: #333!important;
}
.gustos-box {
    /* min-height: 200px; */    
    background-color: #fff;
    border-radius: 5px;
    padding: 25px;
    margin: 1%;
    flex: 1 1 auto;
    /* max-width: 230px;
    min-width: 200px; */
    width: 10vw;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, .2);
}

.gustos-list li{
    color: #333 !important;
    font-size: 1rem;
    margin: 12px 0;
}
.gustos-list {
    list-style: none;
    padding: 0;
}
.gustos-grid {
    display: flex;
    flex-wrap: wrap;
    width: 75%;
    align-items: flex-start;
    margin: auto;
    /*
    max-width: 1500px;
    justify-content: center;
    margin-top: 40px;
     margin: 0 auto; */
}

.grid-item {

}
  